import { Typography } from '@mui/material';
import { Widget } from '@uploadcare/react-widget';

export default function ImageUploader({ setImageId }) {
  // The key needs to be added to the .env file
  const publicKey = process.env.REACT_APP_UPLOADCARE_API_KEY;

  function handleImageUpload(file) {
    setImageId(file.uuid);
  }
  return (
    <>
      <Typography paddingTop={1} paddingRight={1}>
        Please select an image:
      </Typography>
      <Widget publicKey={publicKey} id="file" imagesOnly previewStep onChange={handleImageUpload} />
    </>
  );
}
