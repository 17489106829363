import customFetch from '../utils/fetchInstance';

const fetchAllTrainers = (controllerSignal) =>
  customFetch('/trainers/trainer/', {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
  });

const fetchSingleTrainer = (controllerSignal, trainerId) =>
  customFetch(`/trainers/trainer/${trainerId}/`, {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
  });

const updateTrainer = async (trainerId, trainer, setTrainer, callback) => {
  return await customFetch(`/trainers/trainer/${trainerId}/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(trainer),
  })
    .then((response) => {
      if (!response.response.ok) {
        alert('Update Failed');
        callback(`/staff-admin/trainers`);
      } else {
        callback(`/staff-admin/trainers/`);
      }
      return response.data;
    })
    .then((trainerData) => {
      setTrainer(trainerData);
    })
    .catch((err) => console.error(err));
};

const createTrainer = async (trainer, setTrainer, callback) => {
  return await customFetch(`/trainers/trainer/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(trainer),
  })
    .then((response) => {
      if (!response.response.ok) {
        alert('Trainer Creation Failed!');
      }
      return response.data;
    })
    .then((trainerData) => {
      setTrainer(trainerData);
      callback('/staff-admin/trainers');
    })
    .catch((err) => console.error(err));
};

const deleteTrainer = async (trainerId) => {
  return await customFetch(`/trainers/trainer/${trainerId}/`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((response) => {
      return response.response.status;
    })
    .catch((err) => console.log('TRAINER DELETE ERROR:', err));
};

export { fetchSingleTrainer, fetchAllTrainers, deleteTrainer, createTrainer, updateTrainer };
