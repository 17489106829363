import { Box, Stack } from '@mui/material';
import React from 'react';
import useSWR from 'swr';
import MainImageList from '../components/MainImageList';
import SectionHeader from '../components/SectionHeader';

function TrainersPage() {
  const { data: trainerData } = useSWR('/trainers/trainer/');

  if (!trainerData) return <h2>Loading...</h2>;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          mb: 2,
        }}>
        <Stack spacing={4}>
          <>
            <Box
              sx={{
                textAlign: 'left',
                alignContent: 'flex-start',
                paddingLeft: 4,
              }}>
              <SectionHeader
                title="Build Confidence"
                subtitle="Get to where you want to be on and off the bike!"
              />
            </Box>
            <MainImageList data={trainerData} typeOfResource="trainer" />
          </>
        </Stack>
      </Box>
    </>
  );
}

export default TrainersPage;
