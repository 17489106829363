import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function AdminHeader() {
  const navigate = useNavigate();
  return (
    <Box>
      {/* component={"span"} in Typography fixes "div-cannot-appear-as-a-descendant-of-p" error */}
      <Typography
        component={'span'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          padding: '2.5rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          fontSize: '2rem',
          backgroundColor: 'secondary.main',
          textAlign: 'center',
        }}>
        <Box sx={{ paddingRight: '2rem', paddingTop: '0', paddingBottom: '0' }}>
          <Button variant="contained" onClick={() => navigate('/staff-admin/users')}>
            Users
          </Button>
        </Box>
        <Box sx={{ paddingRight: '2rem', paddingTop: '0', paddingBottom: '0' }}>
          <Button variant="contained" onClick={() => navigate('/staff-admin')}>
            Tracks
          </Button>
        </Box>

        <Box sx={{ paddingRight: '2rem', paddingTop: '0', paddingBottom: '0' }}>
          <Button variant="contained" onClick={() => navigate('/staff-admin/shops')}>
            Shops
          </Button>
        </Box>
        <Box sx={{ paddingRight: '2rem', paddingTop: '0', paddingBottom: '0' }}>
          <Button variant="contained" onClick={() => navigate('/staff-admin/trainers')}>
            Trainers
          </Button>
        </Box>
        <Box sx={{ paddingRight: '2rem', paddingTop: '0', paddingBottom: '0' }}>
          <Button variant="contained" onClick={() => navigate('/staff-admin/events')}>
            Events
          </Button>
        </Box>
        <Box sx={{ paddingRight: '2rem', paddingTop: '0', paddingBottom: '0' }}>
          <Button variant="contained" onClick={() => navigate('/staff-admin/heroimages')}>
            Hero Images
          </Button>
        </Box>
      </Typography>
    </Box>
  );
}

export default AdminHeader;
