export const getUserLocation = (setUserLocation) => {
  let lat = 42.30732;
  let lng = -71.08594;
  setUserLocation({ coords: { lat: lat, lng: lng } });

  if (!navigator.geolocation) {
    console.log('Geolocation is not supported by your browser');
  } else {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        lat = position.coords.latitude;
        lng = position.coords.longitude;

        setUserLocation({ coords: { lat: lat, lng: lng } });
      },
      () => {
        console.log('Unable to retrieve your location');
      }
    );
  }
};
