import { createContext, useState } from 'react';

export const MapFilterContext = createContext();

export const MapFilterContextProvider = ({ children }) => {
  const [tracks, setTracks] = useState(null);
  return (
    <MapFilterContext.Provider value={[tracks, setTracks]}>{children}</MapFilterContext.Provider>
  );
};
