import customFetch from '../utils/fetchInstance';
import getCsrfToken from '../utils/getCsrfToken';

const fetchAllEvents = async (controllerSignal) =>
  customFetch('/events/event/', {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
    'X-CSRFToken': getCsrfToken(),
  });

const fetchSingleEvent = (controllerSignal, eventId) =>
  customFetch(`/events/event/${eventId}/`, {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
  });

const createEvent = async (event, setEvent, callback) => {
  return await customFetch(`/events/event/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    body: JSON.stringify(event),
  })
    .then((response) => {
      if (!response.response.ok) {
        alert('Event Creation Failed!');
      }
      return response.data;
    })
    .then((eventData) => {
      setEvent(eventData);
      callback('/staff-admin/events');
    })
    .catch((err) => console.error(err));
};

const updateEvent = async (eventId, event, setEvent, callback) => {
  return await customFetch(`/events/event/${eventId}/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    body: JSON.stringify(event),
  })
    .then((response) => {
      console.log(response);
      if (!response.response.ok) {
        alert('Update Failed');
        callback(`/staff-admin/events`);
      } else {
        callback(`/staff-admin/events/${eventId}`);
      }
      return response.data;
    })
    .then((eventData) => {
      setEvent(eventData);
    })
    .catch((err) => console.error(err));
};

const deleteEvent = async (eventId) => {
  return await customFetch(`/events/event/${eventId}/`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
  })
    .then((response) => {
      console.log('Delete event response:', response);
      return response.response.status;
    })
    .catch((err) => console.log('EVENT DELETE ERROR:', err));
};

export { createEvent, deleteEvent, fetchAllEvents, fetchSingleEvent, updateEvent };
