import { useState, createContext } from 'react';

export const SearchContext = createContext();

export const SearchContextProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <SearchContext.Provider value={[inputValue, setInputValue]}>{children}</SearchContext.Provider>
  );
};
