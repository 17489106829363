import {
  Star,
  LocalPhone,
  PunchClock,
  Web,
  Business,
  BubbleChart,
  Attractions,
} from '@mui/icons-material';
import { Box, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

export default function ViewShop() {
  // get shopId from URL
  const { shopId } = useParams();
  const { data: shop, error: shopError } = useSWR(`/shops/shop/${shopId}/`);

  if (!shop) return <h2>Loading...</h2>;

  return (
    <>
      {!shopError ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            paddingTop: 3,
            paddingBottom: 5,
          }}>
          <Typography variant="h5" className="title">
            {`${shop.name}`}
          </Typography>
          {shop.featured && (
            <Stack direction="row" spacing={3} paddingTop={2}>
              <Star color="secondary" />
              <Typography>FEATURED shop</Typography>
              <Star color="secondary" />
            </Stack>
          )}

          <List sx={{ width: '100%', maxWidth: 500 }}>
            <ListItem>
              <Typography
                sx={{ textAlign: 'justify' }}
                dangerouslySetInnerHTML={{ __html: shop.description }}></Typography>
            </ListItem>
            {shop.website && (
              <ListItem component="a" href={shop.website} target="_blank" sx={{ color: 'black' }}>
                <ListItemIcon>
                  <Web color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Website:'} secondary={shop.website} />
              </ListItem>
            )}
            {shop.formatted_phone_number && (
              <ListItem>
                <ListItemIcon>
                  <LocalPhone color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Phone Number:'} secondary={shop.formatted_phone_number} />
              </ListItem>
            )}

            {shop.products && (
              <ListItem>
                <ListItemIcon>
                  <BubbleChart color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Products:'} />
                {shop.products.map((product) => (
                  <ListItemText secondary={product.name} />
                ))}
              </ListItem>
            )}
            {shop.brands && (
              <ListItem>
                <ListItemIcon>
                  <Attractions color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Brands:'} />
                {shop.brands.map((brand) => (
                  <ListItemText secondary={brand.name} />
                ))}
              </ListItem>
            )}
            {shop.formatted_address && (
              <ListItem>
                <ListItemIcon>
                  <Business color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText
                  primary={'shop formatted address'}
                  secondary={shop.formatted_address}
                />
              </ListItem>
            )}
            {shop.opening_hours && (
              <ListItem>
                <ListItemIcon>
                  <PunchClock color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Open Hours:'} secondary={shop.opening_hours} />
              </ListItem>
            )}
          </List>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            paddingTop: 3,
            paddingBottom: 5,
          }}>
          <p>{shopError}</p>
        </Box>
      )}
    </>
  );
}
