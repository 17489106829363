import { TextField, Button, Box, Alert, InputAdornment, IconButton } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useRef, useState } from 'react';
import ShieldRoundedIcon from '@mui/icons-material/ShieldRounded';
import PersonIcon from '@mui/icons-material/Person';
import { yupResolver } from '@hookform/resolvers/yup';
import signInValidationSchema from './Validations/SignInValidation';

const SignInForm = ({ onSubmit, error }) => {
  const { register, handleSubmit, control, watch } = useForm({
    resolver: yupResolver(signInValidationSchema),
  });
  const password = useRef({});
  password.current = watch('password', '');
  const CHAR_LIMIT = 100;

  // Added to toggle between see password and not
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ minWidth: '30vw' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}>
        {error && (
          <Alert severity="error" sx={{ w: '100%' }}>
            Unable to log in with the provided credentials. Please try again.
          </Alert>
        )}

        <Controller
          name="username"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              inputProps={{ maxLength: CHAR_LIMIT, autoCapitalize: 'none' }}
              margin="normal"
              {...register('username')}
              fullWidth
              id="username"
              name="username"
              label={
                <Box display={'flex'}>
                  <PersonIcon />
                  <Box marginLeft={'5px'}>Username</Box>
                </Box>
              }
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              sx={{ width: '100%', m: 1 }}
            />
          )}
        />
        <Controller
          name="password"
          id="password"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              name="password"
              id="password"
              inputProps={{
                maxLength: CHAR_LIMIT,
                autoCapitalize: 'none',
              }}
              {...register('password')}
              label={
                <Box display={'flex'}>
                  <ShieldRoundedIcon />
                  <Box marginLeft={'5px'}>Password</Box>
                </Box>
              }
              variant="filled"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: '100%', m: 1 }}
            />
          )}
        />
        {
          <Button
            type="submit"
            variant="contained"
            color="info"
            sx={{ p: 1, my: 1, width: '100%', color: 'white' }}>
            Login
          </Button>
        }
      </Box>
    </Box>
  );
};

export default SignInForm;
