import { createTheme } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';

const theme = createTheme({
  palette: createPalette({
    primary: {
      light: '#05c7f2',
      main: '#1976d2',
      dark: '#004a9f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f2cb05',
      main: '#f29f05',
      dark: '#f28705',
      contrastText: '#000',
    },
    info: {
      light: '#5ee1f6',
      main: '#00afc3',
      dark: '#007f93',
      contrastText: '#000',
    },
  }),
  typography: {
    fontFamily: ['Rubik', 'Roboto'].join(','),
  },
});

export default theme;
