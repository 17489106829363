import { AccessAlarm, Business, Map, Star, Web } from '@mui/icons-material';
import { Box, Card, CardContent, Stack, Typography, Link } from '@mui/material';
import convertMilitaryTimeToStandardTime from '../../../utils/military_time_to_standard_time';

export default function EventPlaceCard({ placeData }) {
  let startDate = '';
  // let endDate = '';

  if (placeData.start_time === null) {
    startDate = new Date(placeData.start_date);
  } else {
    startDate = new Date(placeData.start_date + 'T' + placeData.start_time);
  }

  // if (placeData.end_time === null) {
  //   endDate = new Date(placeData.end_date + 'T' + placeData.start_time);
  // } else {
  //   endDate = new Date(placeData.end_date + 'T' + placeData.end_time);
  // }

  return (
    <Card key={placeData.id} sx={{ borderRadius: 4 }} raised>
      <CardContent>
        <Box>
          <Stack direction="row" justifyContent="space evenly" alignItems="center" spacing={2}>
            <Typography color="primary.dark" variant="h6">
              {` ${placeData.name}`}
            </Typography>
            {placeData.featured ? (
              <Box>
                <Star color="secondary" fontSize="large" />
              </Box>
            ) : null}
          </Stack>

          <Typography
            variant="subtitle2"
            sx={{
              alignItems: 'center',
              display: 'flex',
              justify: 'center',
            }}
            color="gray"
            gutterBottom>
            <Map color="primary" sx={{ pr: 2 }} />
            {placeData.location}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              alignItems: 'center',
              display: 'flex',
              justify: 'center',
            }}
            color="gray"
            gutterBottom>
            <AccessAlarm color="primary" sx={{ pr: 2 }} />
            {convertMilitaryTimeToStandardTime(startDate)}
          </Typography>

          {placeData.website && (
            <Typography
              variant="subtitle2"
              sx={{
                alignItems: 'center',
                display: 'flex',
                justify: 'center',
              }}
              color="gray"
              gutterBottom>
              <Web color="primary" sx={{ pr: 2 }} /> Website:{' '}
              <Link href={placeData.website} color="inherit">
                {placeData.website}
              </Link>
            </Typography>
          )}

          {placeData.formatted_address && (
            <Typography
              variant="subtitle2"
              sx={{
                alignItems: 'center',
                display: 'flex',
                justify: 'center',
              }}
              color="gray"
              gutterBottom>
              <Business color="primary" sx={{ pr: 2 }} />
              {placeData.formatted_address}
            </Typography>
          )}

          {/* {placeData.series && (
            <Typography
              variant="subtitle2"
              sx={{
                alignItems: 'center',
                display: 'flex',
                justify: 'center',
              }}
              color="gray"
              gutterBottom>
              <Event color="primary" sx={{ pr: 2 }} />
              {placeData.series}
            </Typography>
          )} */}

          <Typography
            variant="body1"
            sx={{
              alignItems: 'center',
              display: 'inline',
              justify: 'center',
              wordWrap: 'break-word',
            }}
            color="text.primary"
            gutterBottom
            dangerouslySetInnerHTML={{ __html: placeData.description }}></Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
