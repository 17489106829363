import { useState } from 'react';
import useSWR, { mutate } from 'swr';
import { useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, Link, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit, Add } from '@mui/icons-material';

// import { deleteTrack, fetchAllTracks } from '../../services/Tracks';
import { deleteEvent } from '../../services/Events';
import AdminHeader from '../../components/staff-admin/AdminHeader';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import toTitleCase from '../../utils/toTitleCase';
//Styles
import './Lists.css';

export default function EventsList() {
  let navigate = useNavigate();

  // State of list items
  const { data: events, error } = useSWR('/events/event/');
  const [event, setEvent] = useState([]);

  // structured based on https://stackoverflow.com/a/68052165 to avoid eslint errors
  // and
  // https://typeofnan.dev/how-to-abort-multiple-fetch-requests-in-javascript-using-abortcontroller/
  // to cleanup async effects
  // useEffect(() => {
  //   let controller = new AbortController();
  //   (async () => {
  //     let response, eventData;
  //     try {
  //       response = await fetchAllEvents(controller.signal);
  //       eventData = response.data;
  //     } catch (err) {
  //       setError(err);
  //     }
  //     setEvents(eventData);
  //   })();
  //   return () => controller?.abort();
  // }, []);

  // Map through rows for MUI X DataGrid
  const rows = events?.map((row) => ({
    id: row.id,
    location: row.location,
    names: row.name,
  }));

  // Columns for MUI X DataGrid
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'names',
      headerName: 'Event Name',
      flex: 0.9,
      renderCell: ({ row: { id, names } }) => {
        return (
          <Link onClick={() => navigate(`/staff-admin/events/${id}`)} underline="hover">
            {names}
          </Link>
        );
      },
    },

    {
      field: 'location',
      headerName: 'Location',
      type: 'string',
      flex: 0.5,
      valueGetter: ({ value }) => value && toTitleCase(value),
    },
    {
      feild: 'edit',
      headerName: 'Actions',
      flex: 0.3,
      headerAlign: 'center',
      renderCell: ({ row: { id, names } }) => {
        return (
          <Box>
            <Tooltip title="Edit Event">
              <IconButton
                className="button-shadow"
                aria-label={`edit event named ${names}`}
                color="info"
                align="center"
                size="small"
                sx={{ mr: '1.9rem', ml: '1rem' }}
                onClick={() => navigate(`/staff-admin/events/edit/${id}`)}
                underline="hover">
                <Edit />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete Event">
              <IconButton
                className="button-shadow"
                aria-label={`delete event named ${names}`}
                color="error"
                align="center"
                size="small"
                onClick={() => {
                  setDialogOpen(true);
                  setEvent(id);
                }}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  // Delete dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  // Deletes the item from the list and database
  const handleDelete = (eventId) => {
    deleteEvent(eventId).then((response) => {
      if (response < 300) {
        mutate(
          '/events/event/',
          (events) => {
            const newEvents = events.filter((e) => e.id !== eventId);
            return newEvents;
          },
          false
        );
      } else {
        alert('Delete Failed');
      }
    });
  };

  return (
    <>
      <AdminHeader />
      <Box m="0 auto" mt="20px" width="90vw" height="5vh">
        <Tooltip title="Add Event">
          <Button
            className="button-shadow"
            sx={{
              ml: '.07rem',
              fontWeight: 'bold',
              fontSize: '1.2rem',
              paddingLeft: '1rem',
              paddingRight: '1.25rem',
              borderRadius: '50px',
            }}
            onClick={() => navigate(`/staff-admin/events/add`)}
            color="info"
            startIcon={<Add color="info" />}
            size="medium">
            Event
          </Button>
        </Tooltip>
      </Box>
      {events && !error ? (
        <Box
          m="0 auto"
          mt="20px"
          width="90vw"
          height="75vh"
          sx={{ '& .MuiDataGrid-root': { border: 'none' } }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                },
              },
            }}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: '1.3rem',
              },
              '& .MuiDataGrid-cellContent': {
                fontSize: '1rem',
                fontWeight: 'bold',
                color: 'primary.main',
              },
              '& .MuiTypography-root': { fontWeight: 'bold', fontSize: '1rem' },
            }}
          />

          {/*Popup to confirm they want to delete*/}
          <DeleteConfirmation
            open={dialogOpen}
            setOpen={setDialogOpen}
            onConfirm={() => handleDelete(event)}>
            Are you sure you want to delete?
          </DeleteConfirmation>
        </Box>
      ) : (
        <p>error</p>
      )}
    </>
  );
}
