import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { Box, Button, Grid, Typography } from '@mui/material';
import { createHeroImage, deletePhoto, deleteUploadedImage } from '../../services/Uploadcare';
import ImageUploader from '../../components/ImageUploader';
import AdminImageList from '../../components/AdminImageList';

export default function Hero() {
  // Gets the image id from the image uploader to be able to store it on the backend
  const [imageUploadedId, setImageUploadedId] = useState(null);
  const { data: images, error } = useSWR('/photos/hero-image-photos/');
  let navigate = useNavigate();

  // Save changes to the database
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (imageUploadedId) {
      const response = await createHeroImage('hero-image', imageUploadedId);
      if (response) {
        mutate('/photos/hero-image-photos/');
        alert('Image succesfully added!');
      }
    }
  };

  // Delete the image from the backend and from the service
  const handleImageDelete = (imageId, uploadcareId) => {
    deletePhoto('hero-image', imageId, uploadcareId).then((response) => {
      if (response < 300) {
        mutate('/photos/hero-image-photos/', (heroImages) => {
          return heroImages.filter((image) => image.uploadcare_uuid !== imageUploadedId);
        });
      } else {
        alert('Delete Failed');
      }
    });
  };

  // Cancel changes. If there is an image upload we need to delete it from the service
  const handleCancel = () => {
    if (imageUploadedId) {
      deleteUploadedImage(imageUploadedId);
    }
    navigate('/staff-admin');
  };

  return (
    <Box width="100%">
      <Grid container paddingY={2} rowSpacing={2} direction="row" justifyContent="center">
        {/* Centered Grid Item */}
        <Grid item xs={12} marginX={8} style={{ display: 'flex', justifyContent: 'center' }}>
          <ImageUploader setImageId={setImageUploadedId} />
        </Grid>

        <Grid item xs={12}>
          {error ? (
            <Typography>{error.text}</Typography>
          ) : (
            <AdminImageList
              images={images}
              deleteImage={handleImageDelete}
              placeName={'hero-image'}
            />
          )}
        </Grid>
      </Grid>
      <Grid container paddingTop={2} justifyContent="center">
        <Grid paddingRight={1}>
          <Button type="submit" variant="outlined" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </Grid>
        <Grid paddingRight={1}>
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
