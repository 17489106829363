import customFetch from '../utils/fetchInstance';
import getCsrfToken from '../utils/getCsrfToken';

////////////////////////////////////////////////////////////////////////////////
// These functions connect to the backend endpoints to perform CRUD operations
////////////////////////////////////////////////////////////////////////////////

const fetchAllTracks = (controllerSignal) =>
  customFetch('/tracks/track/', {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
  });

const fetchSingleTrack = (controllerSignal, trackId) =>
  customFetch(`/tracks/track/${trackId}/`, {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
  });

const createTrack = async (track, setTrack, callback) => {
  return await customFetch(`/tracks/track/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    body: JSON.stringify(track),
  })
    .then((response) => {
      if (!response.response.ok) {
        alert('Track Creation Failed!');
      }
      return response.data;
    })
    .then((trackData) => {
      setTrack(trackData);
      callback('/staff-admin/tracks');
    })
    .catch((err) => console.error(err));
};

const updateTrack = async (trackId, track, setTrack, callback) => {
  return await customFetch(`/tracks/track/${trackId}/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    body: JSON.stringify(track),
  })
    .then((response) => {
      console.log(response);
      if (!response.response.ok) {
        alert('Update Failed');
        callback(`/staff-admin/tracks`);
      } else {
        callback(`/staff-admin/track/${trackId}`);
      }
      return response.data;
    })
    .then((trackData) => {
      setTrack(trackData);
    })
    .catch((err) => console.error(err));
};

const deleteTrack = async (trackId) => {
  return await customFetch(`/tracks/track/${trackId}/`, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
  })
    .then((response) => {
      console.log('Delete track response:', response);
      return response.response.status;
    })
    .catch((err) => console.log('TRACK DELETE ERROR:', err));
};

export { fetchAllTracks, fetchSingleTrack, updateTrack, createTrack, deleteTrack };
