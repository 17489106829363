import { useState, createContext } from 'react';

export const ZipLocationContext = createContext();

export const ZipLocationContextProvider = ({ children }) => {
  const [zipData, setZipData] = useState();

  return (
    <ZipLocationContext.Provider value={[zipData, setZipData]}>
      {children}
    </ZipLocationContext.Provider>
  );
};
