import { deleteFile, UploadcareSimpleAuthSchema } from '@uploadcare/rest-client';
import customFetch from '../utils/fetchInstance';
import getCsrfToken from '../utils/getCsrfToken';

// When you create an account with the service you will get the public key
// But when using REST API functions you will need an authentication method and
// to create a SECRET_KEY in their dashboard
// Right now we are using the simple one but it's not recommended for production
// TODO: Change the auth method to the recommended one.

const uploadcareSimpleAuthSchema = new UploadcareSimpleAuthSchema({
  publicKey: process.env.REACT_APP_UPLOADCARE_API_KEY,
  secretKey: process.env.REACT_APP_UPLOADCARE_SECRET_KEY,
});

// This function deletes the image that is already in the service
// it does not delete anything from our backend
// Needs to be called if we are going to clear the images for the track/shop/user
export const deleteUploadedImage = async (imageId) => {
  const result = await deleteFile({ uuid: imageId }, { authSchema: uploadcareSimpleAuthSchema });
  console.log(result);
};

// typeOfResource is basically the end point or the model we need to get the data
// track, shop or user at the moment

// TODO: Endpoint for single resource needed. For now we are fetching all the images and after we are going to filter them.
// This can be refactored adding the photos to the main model (track, shop or user)
export const getUploadedImages = async (controllerSignal, typeOfResource) => {
  return await customFetch(`/photos/${typeOfResource}-photos/`, {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
  });
};

export const getImageUrl = (imageId) => {
  return `https://ucarecdn.com/${imageId}/`;
};

export const createPhoto = async (typeOfResource, resourceId, photoId) => {
  let columnName = typeOfResource;
  if (typeOfResource === 'profile') {
    columnName = 'user';
  }
  const photoData = {
    uploadcare_uuid: photoId,
    [columnName]: resourceId,
  };

  return await customFetch(`/photos/${typeOfResource}-photos/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    body: JSON.stringify(photoData),
  })
    .then((response) => {
      if (!response.response.ok) {
        alert(`${typeOfResource} Image Addition Failed!`);
      }
      return response.data;
    })
    .catch((err) => console.error(err));
};

export const createHeroImage = async (typeOfResource, photoId) => {
  const photoData = {
    uploadcare_uuid: photoId,
  };

  return await customFetch(`/photos/${typeOfResource}-photos/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    body: JSON.stringify(photoData),
  })
    .then((response) => {
      if (!response.response.ok) {
        alert(`${typeOfResource} Image Addition Failed!`);
      }
      return response.data;
    })
    .catch((err) => console.error(err));
};

export const deletePhoto = async (typeOfResource, resourceId, photoId) => {
  return await customFetch(`/photos/${typeOfResource}-photos/${resourceId}/`, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
  })
    .then((response) => {
      deleteUploadedImage(photoId);
      return response.response.status;
    })
    .catch((err) => console.log('IMAGE DELETE ERROR:', err));
};
