import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Avatar,
  //   CircularProgress, // comenting out CircularProgres, making CI fail.
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuthContext } from '../hooks/AuthContext';
import { getUserLocation } from '../components/utility/Geocode';
import { UserLocationContext } from '../hooks/UserLocationContext';
import SignInForm from '../components/composites/forms/SignInForm';
import picture_three from '../assets/img/picture_three.jpg';

export default function SignIn() {
  const [errores, setErrores] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuthContext();

  const [, setUserLocation] = useContext(UserLocationContext);

  useEffect(() => {
    getUserLocation(setUserLocation);
  }, [setUserLocation]);

  let from = location.state?.from?.pathname || '/';
  let domain = window.location.origin;
  let backendDomain = domain;
  if (domain.includes('localhost')) {
    backendDomain = 'http://localhost:8000';
  }

  const handleFormSubmit = ({ username, password }) => {
    auth
      .signIn({ username, password }, () => {
        navigate(from, { replace: true });
      })
      .then((err) => {
        console.log(err);
        if (err) {
          setErrores(true);
        }
        // else {
        //   setErrores(false);
        // }
      });
  };

  if (auth.userData.loggedIn) {
    navigate('/', { replace: true });
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          // backgroundImage: "url(https://drive.google.com/uc?id=1hjoBMlX7mIrUQhCinrSXKWatmprScMa5)",
          backgroundImage: `url(${picture_three})`,
          loading: 'lazy',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '3024',
          height: '4032',
        }}
      />

      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" gutterBottom>
            Sign in
          </Typography>

          <Typography variant="subtitle2" gutterBottom paragraph align="justify">
            <b>Off Road Fun Finder</b> is a new app that will elevate the sport as we know it. Find
            every track, training, and event Nationwide! Compete with Moto Passes. Complete tasks at
            participating tracks and events to have a chance at winning Bikes, Training, Gear, Sx/Mx
            tickets and more. Just be sure your location settings are on to optimize your
            experience.
            <br></br>
            <br></br>
            <mark>
              Sign up today for your free membership and automatically be entered to win a brand new
              Honda CRF 110. No purchase necessary.
            </mark>
          </Typography>

          <Box sx={{ mt: 1, width: 'full' }}>
            <SignInForm onSubmit={handleFormSubmit} error={errores} />
            <Box display={'flex'} flexDirection="column-reverse">
              <Button
                href={`${domain}/signup`}
                sx={{ selfAlign: 'end', py: 1, w: '100%' }}
                variant="text">
                Don't have an account? Register here
              </Button>
              <Button
                href={`${backendDomain}/auth/password-reset/`}
                sx={{ selfAlign: 'end', py: 1, w: '100%' }}>
                Forgot Password
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
