// @ts-nocheck
import { LinearProgress } from '@mui/material';
import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { useAuthContext } from './hooks/AuthContext';
import { MapFilterContextProvider } from './hooks/MapFilterContext';
import { SearchContextProvider } from './hooks/SearchContext';
import { ZipLocationContextProvider } from './hooks/ZipLocationContext';
import EventsList from './pages/Admin/EventsList';
import ShopsList from './pages/Admin/ShopsList';
import TrackList from './pages/Admin/TrackList';
// import TrainersAddEdit from './pages/Admin/TrainersAddEdit';
import TrainersList from './pages/Admin/TrainersList';
import ViewTrainer from './pages/Admin/ViewTrainer';
import ViewTrack from './pages/Admin/ViewTrack';
import Events from './pages/Events';
import SignIn from './pages/SignIn';
import TrainersPage from './pages/TrainersPage';
import { fetchUserData } from './services/Auth';
import customFetch from './utils/fetchInstance';
import Hero from './pages/Admin/Hero';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ViewShop from './pages/Admin/ViewShop';
import React from 'react';
import MobileNumberMessage from './components/composites/MobileNumberMessage';

// Lazy Loaded Components/Pages

const ViewEvent = lazy(() => import('./pages/Admin/ViewEvent'));
const EventsAddEdit = lazy(() => import('./pages/Admin/EventsAddEdit'));
const TrackAddEdit = lazy(() => import('./pages/Admin/TrackAddEdit'));
const ShopAddEdit = lazy(() => import('./pages/Admin/ShopAddEdit'));
const TrainersAddEdit = lazy(() => import('./pages/Admin/TrainersAddEdit'));

const Header = lazy(() => import('./components/composites/Header'));
const Footer = lazy(() => import('./components/Footer'));
const PrivateRoute = lazy(() => import('./components/utility/PrivateRoute'));
const Home = lazy(() => import('./pages/Home'));
const Help = lazy(() => import('./pages/Help'));
const TestingArea = lazy(() => import('./pages/TestingArea'));
const Profile = lazy(() => import('./pages/Profile'));

const TrackExplorerMap = lazy(() => import('./pages/TrackExplorerMap'));
const MotoPass = lazy(() => import('./pages/MotoPass'));
// const ViewTrack = lazy(() => import("./pages/Admin/ViewTrack"));
const ViewUsers = lazy(() => import('./pages/Admin/ViewUsers'));
const UsersList = lazy(() => import('./pages/Admin/UsersList'));
// const SignIn = lazy(() => import("./pages/SignIn"));
const SignUp = lazy(() => import('./pages/SignUp'));
const UserAddEdit = lazy(() => import('./pages/Admin/UserAddEdit'));
const MonthlyMembership = lazy(() => import('./Payment/MonthlyMembership'));
const AnnualMembership = lazy(() => import('./Payment/AnnualMembership'));
const Memberships = lazy(() => import('./Payment/Memberships'));

export default function App() {
  const { setUserData } = useAuthContext();
  //const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);
  //console.log("TESTING TESTING TESTING\n" + process.env.REACT_APP_STRIPE_PUB_KEY);

  useEffect(() => {
    fetchUserData(setUserData);
    // eslint-disable-next-line
  }, []);
  return (
    <Suspense fallback={<LinearProgress sx={{ width: '100%' }} />}>
      <SWRConfig
        value={{
          fetcher: async (url) => {
            const res = await customFetch(url, {
              method: 'GET',
              credentials: 'same-origin',
            });
            return res.data;
          },
        }}>
        <BrowserRouter>
          <Header />
          <MobileNumberMessage />
          <div
            style={{
              minHeight: '85vh',
            }}>
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route
                path="/home"
                element={
                  <PrivateRoute>
                    <ZipLocationContextProvider>
                      <MapFilterContextProvider>
                        <SearchContextProvider>
                          <Home />
                        </SearchContextProvider>
                      </MapFilterContextProvider>
                    </ZipLocationContextProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/help"
                element={
                  <PrivateRoute>
                    <Help />
                  </PrivateRoute>
                }
              />
              <Route
                path="/motoPass"
                element={
                  <PrivateRoute>
                    <MotoPass />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/trainers"
                element={
                  <PrivateRoute>
                    <TrainersPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/events"
                element={
                  <PrivateRoute>
                    <Events />
                  </PrivateRoute>
                }
              />
              <Route
                path="/staff-admin/*"
                element={
                  <PrivateRoute>
                    <TrackList />
                  </PrivateRoute>
                }
              />
              {/*This is not currently being used because we only have tracks in admin, but will use when users get added in*/}
              {/*<Route*/}
              {/*  path="staff-admin/tracks"*/}
              {/*  element={*/}
              {/*    <PrivateRoute>*/}
              {/*      <TrackList />*/}
              {/*    </PrivateRoute>*/}
              {/*  }*/}
              {/*/>*/}

              {/*Tracks*/}
              <Route
                path="staff-admin/tracks/add"
                element={
                  <PrivateRoute>
                    <TrackAddEdit />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/track/:trackId"
                element={
                  <PrivateRoute>
                    <ViewTrack />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/track/edit/:trackId"
                element={
                  <PrivateRoute>
                    <TrackAddEdit />
                  </PrivateRoute>
                }
              />
              {/*Users*/}
              <Route
                path="staff-admin/users"
                element={
                  <PrivateRoute>
                    <UsersList />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/user/:userId"
                element={
                  <PrivateRoute>
                    <ViewUsers />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/user/edit/:userId"
                element={
                  <PrivateRoute>
                    <UserAddEdit />
                  </PrivateRoute>
                }
              />
              {/*when this is ready to be merged and have buttons connecting them these need to
             be changed to PrivateRoutes like the routes above*/}
              <Route
                path="staff-admin/user/add"
                element={
                  <PrivateRoute>
                    <UserAddEdit />
                  </PrivateRoute>
                }
              />
              {/*Shops*/}
              <Route
                path="staff-admin/shops"
                element={
                  <PrivateRoute>
                    <ShopsList />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/shops/shop/:shopId"
                element={
                  <PrivateRoute>
                    <ViewShop />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/shops/add"
                element={
                  <PrivateRoute>
                    <ShopAddEdit />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/shops/shopEdits/:shopId"
                element={
                  <PrivateRoute>
                    <ShopAddEdit />
                  </PrivateRoute>
                }
              />
              {/* Events */}
              <Route
                path="staff-admin/events"
                element={
                  <PrivateRoute>
                    <EventsList />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/events/:eventId"
                element={
                  <PrivateRoute>
                    <ViewEvent />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/events/add"
                element={
                  <PrivateRoute>
                    <EventsAddEdit />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/events/edit/:eventId"
                element={
                  <PrivateRoute>
                    <EventsAddEdit />
                  </PrivateRoute>
                }
              />
              {/* Trainers */}
              <Route
                path="staff-admin/trainers"
                element={
                  <PrivateRoute>
                    <TrainersList />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/trainers/add"
                element={
                  <PrivateRoute>
                    <TrainersAddEdit />
                  </PrivateRoute>
                }
              />
              <Route
                path="staff-admin/trainers/edit/:trainerId"
                element={
                  <PrivateRoute>
                    <TrainersAddEdit />
                  </PrivateRoute>
                }
              />
              <Route
                path="/staff-admin/trainers/:trainerId"
                element={
                  <PrivateRoute>
                    <ViewTrainer />
                  </PrivateRoute>
                }
              />

              {/* Hero Images */}
              <Route
                path="staff-admin/heroimages"
                element={
                  <PrivateRoute>
                    <Hero />
                  </PrivateRoute>
                }
              />

              {/*when this is ready to be merged and have buttons connecting them these need to
              be changed to PrivateRoutes like the routes above*/}

              {/*<Route*/}
              {/*  path="staff-admin/user/add"*/}
              {/*  element={<TempUserPage />}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path="staff-admin/user/:userId"*/}
              {/*  element={<TempUserPage />}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path="staff-admin/user/edit/:userId"*/}
              {/*  element={<TempUserPage />}*/}
              {/*/>*/}
              <Route path="/testing" element={<TestingArea />} />
              {/* <Route path="/login" element={<LogIn />} /> */}
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route
                path="/map"
                element={
                  <PrivateRoute>
                    <ZipLocationContextProvider>
                      <MapFilterContextProvider>
                        <SearchContextProvider>
                          <TrackExplorerMap />
                        </SearchContextProvider>
                      </MapFilterContextProvider>
                    </ZipLocationContextProvider>
                  </PrivateRoute>
                }
              />
              <Route path="/checkout" element={<MonthlyMembership />} />
              <Route path="/checkout2" element={<AnnualMembership />} />
              <Route path="/memberships" element={<Memberships />} />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </SWRConfig>
    </Suspense>
  );
}
