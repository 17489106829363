import { Add, Delete, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Link, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import AdminHeader from '../../components/staff-admin/AdminHeader';
import { deleteShop } from '../../services/Shops';
import toTitleCase from '../../utils/toTitleCase';
//Styles
import './Lists.css';

export default function ShopList() {
  let navigate = useNavigate();

  // State of list items

  const [shopIdToDelete, setShopIdToDelete] = useState(undefined);
  const { data: shopData, error: shopError } = useSWR('/shops/shop/');

  // Delete dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  // Map through rows for MUI X DataGrid
  const rows = shopData.map((row) => ({
    id: row.id,
    names: row.name,
    state: row.state,
  }));

  // Columns for MUI X DataGrid
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'names',
      headerName: 'Shop Name',
      flex: 0.9,
      renderCell: ({ row: { id, names } }) => {
        return (
          <Link onClick={() => navigate(`/staff-admin/shops/shop/${id}`)} underline="hover">
            {names}
          </Link>
        );
      },
    },

    {
      field: 'state',
      headerName: 'State',
      type: 'string',
      flex: 0.5,
      valueGetter: ({ value }) => value && toTitleCase(value),
    },
    {
      feild: 'edit',
      headerName: 'Actions',
      flex: 0.3,
      headerAlign: 'center',
      renderCell: ({ row: { id, names } }) => {
        return (
          <Box>
            <Tooltip title="Edit Shop">
              <IconButton
                className="button-shadow"
                aria-label={`edit shop named ${names}`}
                color="info"
                align="center"
                size="small"
                sx={{ mr: '1.9rem', ml: '1rem' }}
                onClick={() => navigate(`/staff-admin/shops/shopEdits/${id}`)}
                underline="hover">
                <Edit />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete Shop">
              <IconButton
                className="button-shadow"
                aria-label={`delete shop named ${names}`}
                color="error"
                align="center"
                size="small"
                onClick={() => {
                  setDialogOpen(true);
                  setShopIdToDelete(id);
                }}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  // Deletes the item from the list and database.
  const handleDelete = (shopId) => {
    deleteShop(shopId).then((response) => {
      if (response < 300) {
        mutate('/shops/shop/', (shops) => {
          return shops.filter((s) => s.id !== shopId);
        });
      } else {
        alert('Delete Failed');
      }
    });
  };

  return (
    <>
      <AdminHeader />
      <Box m="0 auto" mt="20px" width="90vw" height="5vh">
        <Tooltip title="Add Shop">
          <Button
            className="button-shadow"
            sx={{
              ml: '.07rem',
              fontWeight: 'bold',
              fontSize: '1.2rem',
              paddingLeft: '1rem',
              paddingRight: '1.25rem',
              borderRadius: '50px',
            }}
            onClick={() => navigate(`/staff-admin/shops/add`)}
            color="info"
            startIcon={<Add color="info" />}
            size="medium">
            Shops
          </Button>
        </Tooltip>
      </Box>
      {!shopError ? (
        <Box
          m="0 auto"
          mt="20px"
          width="90vw"
          height="75vh"
          sx={{ '& .MuiDataGrid-root': { border: 'none' } }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                },
              },
            }}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: '1.3rem',
              },
              '& .MuiDataGrid-cellContent': {
                fontSize: '1rem',
                fontWeight: 'bold',
                color: 'primary.main',
              },
              '& .MuiTypography-root': { fontWeight: 'bold', fontSize: '1rem' },
              '& .MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 'bold',
                color: 'primary.main',
              },
            }}
          />

          {/*Popup to confirm they want to delete*/}
          <DeleteConfirmation
            open={dialogOpen}
            setOpen={setDialogOpen}
            onConfirm={() => handleDelete(shopIdToDelete)}>
            Are you sure you want to delete?
          </DeleteConfirmation>
        </Box>
      ) : (
        <p>error</p>
      )}
    </>
  );
}
