import * as yup from 'yup';
const signInValidationSchema = yup.object().shape({
  username: yup.string().required('Username is required.'),
  password: yup
    .string()
    .min(9, ({ min }) => `Password must be at least ${min} characters.`)
    .required('Password is required.'),
});

export default signInValidationSchema;
