import { Container, Box } from '@mui/material';
import PlaceCard from './composites/PlaceResultCard/PlaceCard';
import compareByFeatured from '../utils/compareByFeatured';

// typeOfResource is basically the end point or the model we need to get the data
// track, shop or user at the moment
export default function MainImageList({ data, typeOfResource }) {
  return (
    <Container maxWidth="90%">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            sm: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          rowGap: 4,
          columnGap: 2,
        }}
        disabled>
        {/* sorts array to prioritize featured tracks to the top of the list before mapping to place card. More detailed explanation in compareByFeatured.js*/}
        {data.sort(compareByFeatured()).map((i) => (
          <PlaceCard
            placeData={i}
            key={i.id}
            expandable={false}
            images={i.photos}
            typeOfResource={typeOfResource}
          />
        ))}
      </Box>
    </Container>
  );
}
