import { useParams } from 'react-router-dom';
import { Box, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import {
  Business,
  BubbleChart,
  PunchClock,
  MonetizationOn,
  LocalPhone,
  Attractions,
  Web,
  DirectionsBike,
  SportsScore,
  Star,
} from '@mui/icons-material';
import useSWR from 'swr';

export default function ViewTrack() {
  // get trackId from URL
  const { trackId } = useParams();
  const { data: track, error: trackError } = useSWR(`/tracks/track/${trackId}/`);

  if (!track) return <h2>Loading...</h2>;

  return (
    <>
      {!trackError ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            paddingTop: 3,
            paddingBottom: 5,
          }}>
          <Typography variant="h5" className="title">
            {track.name}
          </Typography>
          {track.featured && (
            <Stack direction="row" spacing={3} paddingTop={2}>
              <Star color="secondary" />
              <Typography>FEATURED TRACK</Typography>
              <Star color="secondary" />
            </Stack>
          )}
          <List sx={{ width: '100%', maxWidth: 500 }}>
            <ListItem>
              <Typography
                sx={{ textAlign: 'justify' }}
                dangerouslySetInnerHTML={{ __html: track.description }}></Typography>
            </ListItem>
            {track.website && (
              <ListItem component="a" href={track.website} target="_blank" sx={{ color: 'black' }}>
                <ListItemIcon>
                  <Web color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Website:'} secondary={track.website} />
              </ListItem>
            )}
            {track.formatted_phone_number && (
              <ListItem>
                <ListItemIcon>
                  <LocalPhone color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Phone Number:'} secondary={track.formatted_phone_number} />
              </ListItem>
            )}
            {track.formatted_address && (
              <ListItem>
                <ListItemIcon>
                  <Business color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Address:'} secondary={track.formatted_address} />
              </ListItem>
            )}
            {track.track_types && (
              <ListItem>
                <ListItemIcon>
                  <BubbleChart color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Track Types:'} secondary={track.track_types} />
              </ListItem>
            )}
            {track.amenities && (
              <ListItem>
                <ListItemIcon>
                  <Attractions color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Amenities:'} secondary={track.amenities} />
              </ListItem>
            )}
            {track.opening_hours && (
              <ListItem>
                <ListItemIcon>
                  <PunchClock color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Open Hours:'} secondary={track.opening_hours} />
              </ListItem>
            )}
            {track.fees && (
              <ListItem>
                <ListItemIcon>
                  <MonetizationOn color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Fees:'} secondary={track.fees} />
              </ListItem>
            )}
            {track.practice_allowed && (
              <ListItem>
                <ListItemIcon>
                  <DirectionsBike color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Practice is allowed'} />
              </ListItem>
            )}
            {track.racing_allowed && (
              <ListItem>
                <ListItemIcon>
                  <SportsScore color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Racing is allowed'} />
              </ListItem>
            )}
            {track.training_facility && (
              <ListItem>
                <ListItemIcon>
                  <DirectionsBike color="primary" sx={{ pr: 2 }} />
                </ListItemIcon>
                <ListItemText primary={'Training facility'} />
              </ListItem>
            )}
          </List>
        </Box>
      ) : (
        <p>{trackError}</p>
      )}
    </>
  );
}
