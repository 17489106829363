const removePhoneNumberFormatting = (
  phoneNumber: string | null,
  countryCode: string | null
): string => {
  if (phoneNumber == null || countryCode == null || phoneNumber === '+1') {
    return 'Phone number or country code is null or undefined.';
  }

  const charsToKeep = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const arrayNumber = [...phoneNumber];
  const modifiedArrayNumber = arrayNumber.filter((char) => charsToKeep.includes(char));
  const modifiedPhoneNumber = modifiedArrayNumber.join('');
  return '+' + countryCode + modifiedPhoneNumber;
};

// https://www.twilio.com/docs/glossary/what-e164 (E.164 - International Phone Number Format)
// Function checks if phoneNumber string in E.164 format: '+11234567890'
const isFormatted = (phoneNumber: string): boolean => {
  if (phoneNumber == null || phoneNumber === '+1') {
    return false;
  }

  if (phoneNumber.length === 0) {
    return false;
  }
  const phoneNumberArray = [...phoneNumber];
  const [plusSymbol, ...numbers] = phoneNumberArray;
  const charstoKeep = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const filteredNumbersArray = numbers.filter((char) => charstoKeep.includes(char));
  const isNumeric = filteredNumbersArray.length === numbers.length;
  // ^ checking for non-numeric characters
  const isValidLength = numbers.length > 10 && numbers.length < 16;
  // ^ E.164 phone numbers are generally 11-15 digits
  // ^ For our purposes currently only using US-based phone numbers, numbers.length === 11 would probably be sufficient but want to leave some flexibility for the future

  return plusSymbol === '+' && isNumeric === true && isValidLength === true;
};

// Function expects phoneNumber string in E.164 format: '+11234567890'
const formatUSPhoneNumberForDisplay = (phoneNumber: string | null): string => {
  if (phoneNumber == null || phoneNumber === '+1') {
    return 'Phone number is null or undefined.';
  }

  if (phoneNumber.length === 0) {
    return 'Phone number is empty.';
  }

  if (isFormatted(phoneNumber)) {
    // ^ Checks if phoneNumber is in the expected format (E.164 '+11234567890')
    const areaCode: string = phoneNumber.slice(-10, -7);
    const prefix: string = phoneNumber.slice(-7, -4);
    const lineNumber: string = phoneNumber.slice(-4);
    return `(${areaCode}) ${prefix}-${lineNumber}`;

    // ^ Function returns phoneNumber in US format: '(123) 456-7890'
  }
  return 'Phone number is not in the expected format.';
};

export { removePhoneNumberFormatting, formatUSPhoneNumberForDisplay, isFormatted };
