import { useState, createContext } from 'react';

export const UserLocationContext = createContext();

export const UserLocationContextProvider = ({ children }) => {
  const [location, setLocation] = useState(null);

  return (
    <UserLocationContext.Provider value={[location, setLocation]}>
      {children}
    </UserLocationContext.Provider>
  );
};
