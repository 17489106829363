import { Box, Container } from '@mui/material';
import useSWR from 'swr';
import SectionHeader from '../components/SectionHeader';
import EventPlaceCard from '../components/composites/EventCards/EventPlaceCard';
import compareByFeatured from '../utils/compareByFeatured';

function Events() {
  const { data: eventData } = useSWR('/events/event/');

  if (!eventData) return <h2>Loading...</h2>;

  return (
    <Container maxWidth="90%">
      <SectionHeader
        title="What is going on?"
        subtitle="Races and Events you need to know about!"
      />
      <br></br>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            sm: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          rowGap: 4,
          columnGap: 2,
        }}
        disabled>
        {/* sorts array to prioritize featured tracks to the top of the list before mapping to place card. More detailed explanation in compareByFeatured.js*/}
        {eventData.sort(compareByFeatured()).map((event) => (
          <EventPlaceCard
            placeData={event}
            key={event.id}
            expandable={false}
            // images={images}
            // typeOfResource={typeOfResource}
          />
        ))}
      </Box>
    </Container>
  );
}

export default Events;
