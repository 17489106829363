import {
  Attractions,
  BubbleChart,
  Business,
  EmojiTransportation,
  LocalPhone,
  Map,
  MonetizationOn,
  PunchClock,
  Star,
  Web,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ShowMore from 'react-show-more-button';
import { getImageUrl } from '../../../services/Uploadcare';
import { formatTelLink } from '../../../utils/formatTelLink';
import { formatUSPhoneNumberForDisplay, isFormatted } from '../../../utils/phoneNumberFormatting';

const all_photos = process.env.REACT_APP_ALL_PHOTOS;

// typeOfResource is basically the end point or the model we need to get the data
// track, shop or user at the moment
export default function PlaceCard({
  placeData,
  setSelectedTrack,
  mapVersion = false,
  expandable,
  images,
  typeOfResource,
}) {
  // Components BELOW description
  function CardDetails() {
    return (
      <Box>
        <Stack
          direction="row"
          justifyContent="space evenly"
          alignItems="center"
          spacing={2}></Stack>
        {placeData.track_types && (
          <Typography variant="body2" color="text.primary" gutterBottom>
            <BubbleChart color="primary" sx={{ pr: 2 }} />
            Track Type: {placeData.track_types}
          </Typography>
        )}

        {placeData.opening_hours && (
          <Typography variant="body2" color="text.primary" gutterBottom>
            <PunchClock color="primary" sx={{ pr: 2 }} />
            Hours: {placeData.opening_hours}
          </Typography>
        )}
        {placeData.fees && (
          <Typography variant="body2" color="text.primary" gutterBottom>
            <MonetizationOn color="primary" sx={{ pr: 2 }} />
            Fees: {placeData.fees}
          </Typography>
        )}
        {placeData.amenities && (
          <Typography variant="body2" color="text.primary" gutterBottom>
            <Attractions color="primary" sx={{ pr: 2 }} />
            Amenities: {placeData.amenities}
          </Typography>
        )}
        {placeData.website && (
          <Typography variant="body2" color="text.primary" gutterBottom>
            <Web color="primary" sx={{ pr: 2 }} />
            Website:{' '}
            <Link href={placeData.website} color="inherit">
              {placeData.website}
            </Link>
          </Typography>
        )}

        {placeData.travels && (
          <Typography
            variant="body2"
            sx={{
              pt: 1,
              alignItems: 'center',
              display: 'flex',
              justify: 'center',
            }}
            color="text.primary"
            gutterBottom>
            <EmojiTransportation color="primary" sx={{ pr: 2 }} />
            Travels to Multiple Locations
          </Typography>
        )}
        {isFormatted(placeData.formatted_phone_number) && placeData.featured && (
          <Typography variant="body2" color="text.primary" gutterBottom>
            <LocalPhone color="primary" sx={{ pr: 2 }} />
            Phone Number:{' '}
            <Link href={formatTelLink(placeData.formatted_phone_number)} color="inherit">
              {formatUSPhoneNumberForDisplay(placeData.formatted_phone_number)}
            </Link>
          </Typography>
        )}

        {isFormatted(placeData.phone_number) && (
          <Typography variant="body2" color="text.primary" gutterBottom>
            <LocalPhone color="primary" sx={{ pr: 2 }} />
            Phone Number:{' '}
            <Link href={formatTelLink(placeData.phone_number)} color="inherit">
              {formatUSPhoneNumberForDisplay(placeData.phone_number)}
            </Link>
          </Typography>
        )}
        {placeData.formatted_address && (
          <Typography
            variant="body2"
            sx={{
              pt: 1,
              alignItems: 'center',
              display: 'flex',
              justify: 'center',
            }}
            color="text.primary"
            gutterBottom>
            <Business color="primary" sx={{ pr: 2 }} />
            Address: {placeData.formatted_address}
          </Typography>
        )}
      </Box>
    );
  }

  const [showMore, updateState] = useState(false);
  const handleClick = () => {
    updateState(!showMore);
  };

  // Components ABOVE AND INCLUDING DESCRIPTION
  function CardSummary() {
    let placeDataImages = images.filter((image) => image[typeOfResource] === placeData.id);
    const [showMoreButtonText, setShowMoreButtonText] = useState(true);
    const buttonDisabled = mapVersion === false; //mapVersion makes place card titles clickable on the Map page only

    return (
      <Box>
        <Box>
          {/* Here we verified if the place is featured and if it have images attached to it */}
          {(all_photos || placeData.featured || typeOfResource === 'track') &&
          placeDataImages &&
          placeDataImages.length > 0 ? (
            <CardMedia
              component="img"
              alt={`image for track ${placeData.name}`}
              height="160"
              sx={{ borderRadius: 2 }}
              image={getImageUrl(placeDataImages[0]?.uploadcare_uuid)}
            />
          ) : null}
        </Box>

        <Box sx={{ pt: 2 }}>
          <Button onClick={() => setSelectedTrack(placeData)} disabled={buttonDisabled}>
            <Stack direction="row" justifyContent="space evenly" alignItems="center" spacing={2}>
              {placeData.name ? (
                <Typography color="primary.dark" variant="h6">
                  {placeData.name}
                </Typography>
              ) : (
                <Typography color="primary.dark" variant="h6">
                  {`${placeData?.first_name} ${placeData?.last_name}`}
                </Typography>
              )}
              {placeData.featured ? (
                <Box>
                  <Star color="secondary" fontSize="large" />
                </Box>
              ) : null}
            </Stack>
          </Button>

          <Typography
            variant="subtitle2"
            sx={{
              alignItems: 'center',
              display: 'flex',
              justify: 'center',
            }}
            color="gray"
            gutterBottom>
            <Map color="primary" sx={{ pr: 2 }} />
            {placeData?.state?.replaceAll('-', ' ')}
          </Typography>

          <ShowMore
            maxHeight={150}
            onChange={() => setShowMoreButtonText(!showMoreButtonText)}
            button={<Button>{showMoreButtonText ? 'Show More' : 'Show Less'}</Button>}>
            <Typography
              variant="body1"
              sx={{
                alignItems: 'center',
                display: 'inline',
                justify: 'center',
                wordWrap: 'break-word',
              }}
              color="text.primary"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: placeData.description }}></Typography>
          </ShowMore>
        </Box>
      </Box>
    );
  }

  return expandable ? (
    <Card
      key={placeData.id}
      sx={{ borderRadius: 4 }}
      // style={{
      //   backgroundColor: placeData.featured ? "#FFFFFF" : "white",
      //   borderRadius: 16,
      // }}
      raised>
      <CardContent>
        <CardSummary />
        <CardActions sx={{ justifyContent: 'flex-start' }}>
          <Button sx={{ mt: 1 }} variant="outlined" size="medium" onClick={handleClick}>
            {showMore ? 'Hide details' : 'Show details'}
          </Button>
        </CardActions>
        {showMore && <CardDetails />}
      </CardContent>
    </Card>
  ) : (
    <Card key={placeData.id} sx={{ borderRadius: 4 }} raised>
      <CardContent>
        <CardSummary />
        <CardDetails />
      </CardContent>
    </Card>
  );
}
