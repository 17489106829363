import { Button, Grid, Typography } from '@mui/material';

export default function SectionHeader(props) {
  return (
    <Grid container alignItems="center">
      <Grid item xs={10}>
        <Typography
          variant="h6"
          sx={{
            borderLeft: '2px solid',
            borderColor: 'primary.main',
            paddingLeft: '30px',
          }}>
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            borderLeft: '2px solid',
            borderColor: 'primary.light',
            paddingLeft: '30px',
          }}>
          {props.subtitle}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Button variant="text">{props.buttonText}</Button>
      </Grid>
    </Grid>
  );
}
