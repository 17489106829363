import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import customFetch from '../utils/fetchInstance';
import getCsrfToken from '../utils/getCsrfToken';

const emptyUserObject = {
  id: '',
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  mobile_number: '',
  zipcode: '',
  loggedIn: false,
  is_staff: false,
  date_joined: '',
  photo: {},
};

const isAuthenticated = async () => {
  let userURL = '/auth/user/';
  let refreshToken = sessionStorage.getItem('auth_refresh_token');

  if (refreshToken) {
    const user = jwtDecode(refreshToken);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) {
      userURL = `/auth/user/${user.user_id}/`;
    }
  }

  return await customFetch(userURL, {
    method: 'GET',
    credentials: 'same-origin',
    'X-CSRFToken': getCsrfToken(),
    headers: {
      'Content-type': 'application/json',
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => console.error(err));
};

const fetchUserData = async (setUserData) => {
  try {
    const response = await isAuthenticated();
    if (!response.data.username) {
      console.error('Error Occured: ', response.data.detail);
      setUserData(emptyUserObject);
    } else {
      setUserData({
        id: response.data.id,
        username: response.data.username,
        email: response.data.email,
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        mobile_number: response.data.mobile_number,
        zipcode: response.data.zipcode,
        loggedIn: true,
        is_staff: response.data.is_staff,
        date_joined: response.data.date_joined,
        photo: response.data.photo,
      });
    }
  } catch (error) {
    console.error('Error Occurred:', error);
    setUserData(emptyUserObject);
  }
};

const saveAuthTokens = (tokenData) => {
  // console.log("tokeData:", tokenData);
  sessionStorage.setItem('auth_token', tokenData.access_token);
  sessionStorage.setItem('auth_refresh_token', tokenData.refresh_token);
};

const removeAuthTokens = () => {
  sessionStorage.removeItem('auth_token');
  sessionStorage.removeItem('auth_refresh_token');
};

const register = async (userCredentials, setUserData, setBackendErrors, callback) => {
  // very bare bones: does not have much error handling at all
  return await fetch(`/auth/registration/`, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(userCredentials),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
  })
    .then(async (response) => {
      if (!response.ok) {
        let errorData = await response.json();
        setBackendErrors(errorData);
        throw Error(errorData);
      } else {
        return response.json();
      }
    })
    .then((data) => {
      saveAuthTokens(data);
      setUserData({
        id: data.user.id,
        username: data.user.username,
        email: data.user.email,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        mobile_number: data.user.mobile_number,
        zipcode: data.user.zipcode,
        loggedIn: true,
        is_staff: data.user.is_staff,
        date_joined: data.user.date_joined,
        photo: data.user.photo,
      });
      callback();
    })
    .catch((err) => err);
};

const signIn = async (userCredentials, setUserData, setBackendErrors, callback) => {
  if (userCredentials.password1) {
    // the login endpoints requests 'password', but register requests 'password1', hence why we have this extra piece of code here
    userCredentials.password = userCredentials.password1;
    delete userCredentials.password1;
  }
  return await fetch(`/auth/login/`, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(userCredentials),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
  })
    .then(async (response) => {
      if (!response.ok) {
        let errorData = await response.json();
        setBackendErrors(errorData);
        throw Error(errorData);
      } else {
        return response.json();
      }
    })
    .then((data) => {
      console.log('signIn data:', data);
      saveAuthTokens(data);
      setUserData({
        id: data.user.id,
        username: data.user.username,
        email: data.user.email,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        mobile_number: data.user.mobile_number,
        zipcode: data.user.zipcode,
        loggedIn: true,
        is_staff: data.user.is_staff,
        date_joined: data.user.date_joined,
        photo: data.user.photo,
      });
      callback();
    })
    .catch((err) => err);
};

const signOut = async (setUserData, callback) => {
  return await fetch(`/auth/logout/`, {
    method: 'POST',
    credentials: 'same-origin',
    'X-CSRFToken': getCsrfToken(),
  })
    .then((response) => response.json())
    .then(() => {
      removeAuthTokens();
      setUserData(emptyUserObject);
      callback();
    })
    .catch((err) => err);
};

export { emptyUserObject, fetchUserData, isAuthenticated, register, signIn, signOut };
