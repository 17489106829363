export default function compareByFeatured() {
  return (trackA: { featured: boolean }, trackB: { featured: boolean }): number =>
    Number(trackB.featured) - Number(trackA.featured);
}

// comparison function to feed into .sort() in order to
// sort an array to prioritize featured tracks to the top of the list
// (TLDR: puts featured tracks at top of list)

// When fed into .sort(), it sorts an array in descending order by featured property value (true -> 1, false -> 0); featured is a boolean value; however Number() converts it into a number to use for comparison

// How the sort works:
// B - A -> value (B moves or doesn't move in list)
// 0 - 1 -> -1 (B -> bottom of list; B !featured, A featured)
// 1 - 0 -> 1 (B -> top of list; B featured, A !featured)
// 1 - 1 -> 0 (no change; both featured tracks)
// 0 - 0 -> 0 (no change; both not featured tracks)
