import { useState, createContext, useContext } from 'react';
import * as authService from '../services/Auth';

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(authService.emptyUserObject);
  const [backendErrors, setBackendErrors] = useState(null);

  let signIn = (userCredentials, callback) => {
    return authService.signIn(userCredentials, setUserData, setBackendErrors, callback);
  };

  let register = (userCredentials, callback) => {
    return authService.register(userCredentials, setUserData, setBackendErrors, callback);
  };

  let signOut = (callback) => {
    return authService.signOut(setUserData, callback);
  };

  let value = {
    userData,
    setUserData,
    signIn,
    signOut,
    register,
    backendErrors,
    setBackendErrors,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
