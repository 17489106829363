import { IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { getImageUrl } from '../services/Uploadcare';
import DeleteConfirmation from './DeleteConfirmation';
import { useState } from 'react';

export default function AdminImageList({ images, deleteImage, placeName }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  const getImageDataForDelete = (itemId, uploadcareId) => {
    setSelectedImage({
      id: itemId,
      uploadcare_uuid: uploadcareId,
    });
    setDialogOpen(true);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {/* Example: https://mui.com/material-ui/react-image-list/ */}
      <ImageList sx={{ width: 500, height: 500 }} cols={3} rowHeight={'auto'}>
        {images?.map((item, index) => (
          <>
            <ImageListItem key={item.id}>
              <img
                src={getImageUrl(item.uploadcare_uuid)}
                alt={placeName + ' ' + index}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
                actionIcon={
                  <IconButton onClick={() => getImageDataForDelete(item.id, item.uploadcare_uuid)}>
                    <Delete color="error" />
                  </IconButton>
                }
              />
            </ImageListItem>
            <DeleteConfirmation
              open={dialogOpen}
              setOpen={setDialogOpen}
              onConfirm={() => deleteImage(selectedImage.id, selectedImage.uploadcare_uuid)}>
              Are you sure you want to delete the selected image?
            </DeleteConfirmation>
          </>
        ))}
      </ImageList>
    </div>
  );
}
