import { Box, Button, IconButton, Link, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit, Add } from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import AdminHeader from '../../components/staff-admin/AdminHeader';
import { deleteTrack } from '../../services/Tracks';
import toTitleCase from '../../utils/toTitleCase';
//Styles
import './Lists.css';

export default function TrackList() {
  let navigate = useNavigate();

  // State of list items
  const [trackIdToDelete, setTrackIdToDelete] = useState(undefined);

  const { data: trackData, error: trackError } = useSWR('/tracks/track/');

  // Delete dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  // Map through rows for MUI X DataGrid
  const rows = trackData.map((row) => ({
    id: row.id,
    names: row.name,
    state: row.state,
  }));

  // Columns for MUI X DataGrid
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'names',
      headerName: 'Track Name',
      flex: 1,
      renderCell: ({ row: { id, names } }) => {
        return (
          <Link onClick={() => navigate(`/staff-admin/track/${id}`)} underline="hover">
            {`${names} `}
          </Link>
        );
      },
    },
    {
      field: 'state',
      headerName: 'State',
      type: 'string',
      flex: 0.5,
      valueGetter: ({ value }) => value && toTitleCase(value),
    },
    {
      field: 'edit',
      headerName: 'Actions',
      flex: 0.3,
      headerAlign: 'center',
      renderCell: ({ row: { id, names } }) => {
        return (
          <Box>
            <Tooltip title="Edit Track">
              <IconButton
                className="button-shadow"
                aria-label={`edit track named ${names}`}
                color="info"
                align="center"
                size="small"
                sx={{ mr: '1.9rem', ml: '1rem' }}
                onClick={() => navigate(`/staff-admin/track/edit/${id}`)}
                underline="hover">
                <Edit />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete Track">
              <IconButton
                className="button-shadow"
                aria-label={`delete track named ${names}`}
                color="error"
                align="center"
                size="small"
                onClick={() => {
                  setDialogOpen(true);
                  setTrackIdToDelete(id);
                }}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  // Deletes the item from the list and database
  const handleDelete = (trackId) => {
    deleteTrack(trackId).then((response) => {
      if (response < 300) {
        mutate('/tracks/track/', (tracks) => {
          return tracks.filter((t) => t.id !== trackId);
        });
      } else {
        alert('Delete Failed');
      }
    });
  };

  return (
    <>
      <AdminHeader />
      <Box m="0 auto" mt="20px" width="90vw" height="5vh">
        <Tooltip title="Add Track">
          <Button
            className="button-shadow"
            sx={{
              ml: '.07rem',
              fontWeight: 'bold',
              fontSize: '1.2rem',
              paddingLeft: '1rem',
              paddingRight: '1.25rem',
              borderRadius: '50px',
            }}
            onClick={() => navigate(`/staff-admin/tracks/add`)}
            color="info"
            startIcon={<Add color="info" />}
            size="medium"
            aria-label="Add Track">
            Track
          </Button>
        </Tooltip>
      </Box>
      {!trackError ? (
        <Box>
          <Box
            m="0 auto"
            mt="20px"
            width="90vw"
            height="75vh"
            sx={{ '& .MuiDataGrid-root': { border: 'none' } }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    id: false,
                  },
                },
              }}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                },
                '& .MuiDataGrid-cellContent': {
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: 'primary.main',
                },
                '& .MuiTypography-root': { fontWeight: 'bold', fontSize: '1rem' },
              }}
            />

            {/*Popup to confirm they want to delete*/}
            <DeleteConfirmation
              open={dialogOpen}
              setOpen={setDialogOpen}
              onConfirm={() => handleDelete(trackIdToDelete)}>
              Are you sure you want to delete?
            </DeleteConfirmation>
          </Box>
        </Box>
      ) : (
        <p>error</p>
      )}
    </>
  );
}
