import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import { Box, Button, IconButton, Link, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit, Add } from '@mui/icons-material';

import { deleteTrainer } from '../../services/Trainers';
import { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import AdminHeader from '../../components/staff-admin/AdminHeader';

import toTitleCase from '../../utils/toTitleCase';
import useSWR, { mutate } from 'swr';

export default function TrainersList() {
  let navigate = useNavigate();

  // State of list items
  const [trainerIdToDelete, setTrainerIdToDelete] = useState(undefined);
  const { data: trainerData, error: trainersError } = useSWR('/trainers/trainer/');
  console.log(trainerData);

  // Delete dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  // Map through rows for MUI X DataGrid
  const rows = trainerData?.map((row) => ({
    id: row.id,
    user_first: row.first_name,
    user_last: row.last_name,
    state: row.state,
  }));

  // Columns for MUI X DataGrid
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'user_first',
      headerName: 'Trainer',
      flex: 0.7,

      renderCell: ({ row: { id, user_first, user_last } }) => {
        return (
          <Link onClick={() => navigate(`/staff-admin/trainers/${id}`)} underline="hover">
            {`${user_first} ${user_last}`}
          </Link>
        );
      },
    },
    {
      field: 'state',
      headerName: 'State',
      type: 'string',
      flex: 0.5,
      valueGetter: ({ value }) => value && toTitleCase(value),
    },
    {
      field: 'edit',
      headerName: 'Actions',
      flex: 0.3,
      headerAlign: 'center',
      renderCell: ({ row: { id, user_first, user_last } }) => {
        return (
          <Box>
            <Tooltip title="Edit Trainer">
              <IconButton
                className="button-shadow"
                aria-label="edit"
                color="info"
                align="center"
                size="small"
                sx={{ mr: '1.9rem', ml: '1rem' }}
                onClick={() => navigate(`/staff-admin/trainers/edit/${id}`)}
                underline="hover">
                <Edit />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete Trainer">
              <IconButton
                className="button-shadow"
                aria-label={`delete trainer named ${user_first} ${user_last}`}
                color="error"
                align="center"
                size="small"
                onClick={() => {
                  setDialogOpen(true);
                  setTrainerIdToDelete(id);
                }}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const handleDelete = (trainerId) => {
    deleteTrainer(trainerId).then((response) => {
      if (response < 300) {
        mutate(
          `/trainers/trainer/`,
          (trainers) => {
            const newTrainers = trainers.filter((t) => t.id !== trainerId);
            return newTrainers;
          },
          false
        );
      } else {
        alert('Delete Failed');
      }
    });
  };

  return (
    <>
      <AdminHeader />

      <Box m="0 auto" mt="20px" width="90vw" height="5vh">
        <Tooltip title="Add Trainer">
          <Button
            className="button-shadow"
            sx={{
              ml: '.07rem',
              fontWeight: 'bold',
              fontSize: '1.2rem',
              paddingLeft: '1rem',
              paddingRight: '1.25rem',
              borderRadius: '50px',
            }}
            onClick={() => navigate(`/staff-admin/trainers/add`)}
            color="info"
            startIcon={<Add color="info" />}
            size="medium">
            Trainer
          </Button>
        </Tooltip>
      </Box>
      {!trainersError && trainerData ? (
        <Box>
          <Box
            m="0 auto"
            mt="20px"
            width="90vw"
            height="75vh"
            sx={{ '& .MuiDataGrid-root': { border: 'none' } }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    id: false,
                  },
                },
              }}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                },
                '& .MuiDataGrid-cellContent': {
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: 'primary.main',
                },
                '& .MuiTypography-root': { fontWeight: 'bold', fontSize: '1rem' },
              }}
            />

            <DeleteConfirmation
              open={dialogOpen}
              setOpen={setDialogOpen}
              onConfirm={() => handleDelete(trainerIdToDelete)}>
              Are you sure you want to delete?
            </DeleteConfirmation>
          </Box>
        </Box>
      ) : (
        <p>error</p>
      )}
    </>
  );
}
