import * as React from 'react';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useAuthContext } from '../../../hooks/AuthContext';
import Link from '@mui/material/Link';

const Alert = React.forwardRef(function Alert(props, ref) {
  const [open, setOpen] = useState(true); // Add local state for open

  const handleClose = () => {
    setOpen(false); // Set open to false when the Alert is closed
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    open && ( // Only render when open is true
      <MuiAlert elevation={6} ref={ref} variant="filled" {...props} onClose={handleClose} />
    )
  );
});

export default function MobileNumberMessage() {
  const { userData } = useAuthContext();
  const location = useLocation();
  const doNotShowLocations = ['/signin', '/signup'];
  const showMessage =
    !doNotShowLocations.includes(location.pathname) &&
    (userData.mobile_number === null ||
      userData.mobile_number === undefined ||
      userData.mobile_number === '');

  const [open, setOpen] = React.useState(showMessage);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    showMessage && (
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Alert open={open} onClick={handleClick} onClose={handleClose} severity="warning">
          Enter your mobile number to win exclusive prizes like bikes, training, gear, and more!{' '}
          <Link color="inherit" href="/profile">
            Click here
          </Link>{' '}
          to update your profile.
        </Alert>
      </Stack>
    )
  );
}
