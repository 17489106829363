export default function toTitleCase(str: string) {
  const wordArray = str.toLowerCase().split('-');

  const result = [];

  for (const word of wordArray) {
    result.push(word.charAt(0).toUpperCase() + word.slice(1));
  }

  return result.join(' ');
}
