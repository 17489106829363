import { useParams } from 'react-router-dom';
import { Box, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { Business, LocalPhone, Star } from '@mui/icons-material';
import useSWR from 'swr';

export default function ViewTrainer() {
  // get trainerId from URL
  const { trainerId } = useParams();
  const { data: trainer } = useSWR(`/trainers/trainer/${trainerId}/`);

  if (!trainer) return <h2>Loading...</h2>;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
          paddingTop: 3,
          paddingBottom: 5,
        }}>
        <Typography variant="h5" className="title">
          {trainer.first_name} {trainer.last_name}
        </Typography>
        {trainer.featured && (
          <Stack direction="row" spacing={3} paddingTop={2}>
            <Star color="secondary" />
            <Typography>FEATURED TRAINER</Typography>
            <Star color="secondary" />
          </Stack>
        )}
        <List sx={{ width: '100%', maxWidth: 500 }}>
          <ListItem>
            <Typography
              sx={{ textAlign: 'justify' }}
              dangerouslySetInnerHTML={{ __html: trainer.description }}></Typography>
          </ListItem>
          {trainer.phone_number && (
            <ListItem>
              <ListItemIcon>
                <LocalPhone color="primary" sx={{ pr: 2 }} />
              </ListItemIcon>
              <ListItemText primary={'Phone Number:'} secondary={trainer.phone_number} />
            </ListItem>
          )}
          {trainer.formatted_address && (
            <ListItem>
              <ListItemIcon>
                <Business color="primary" sx={{ pr: 2 }} />
              </ListItemIcon>
              <ListItemText primary={'Address:'} secondary={trainer.formatted_address} />
            </ListItem>
          )}
        </List>
      </Box>
      )
    </>
  );
}
