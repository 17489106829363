import customFetch from '../utils/fetchInstance';
import getCsrfToken from '../utils/getCsrfToken';

const fetchAllShops = async (controllerSignal) =>
  customFetch('/shops/shop/', {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
    'X-CSRFToken': getCsrfToken(),
  });
const fetchProducts = async (controllerSignal) =>
  customFetch('/shops/product/', {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
    'X-CSRFToken': getCsrfToken(),
  });
const fetchBrands = async (controllerSignal) =>
  customFetch('/shops/brand/', {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
    'X-CSRFToken': getCsrfToken(),
  });

/*const fetchSingleShop = async (shopId, setShop) => {
  return await customFetch(`/shops/shop/${shopId}/`, {
    method: "GET",
    credentials: "include",
    "X-CSRFToken": getCsrfToken(),
  })
    .then((response) => {
      if (!response.response.ok) {
        alert("Failed to fetch shop");
      }
      return response.data;
    })
    .then((shopData) => {
      console.log(shopData);
      setShop(shopData);
    })
    .catch((err) => console.log("SHOP FETCH ERROR:", err));
};*/

const fetchSingleShop = (controllerSignal, shopId) =>
  customFetch(`/shops/shop/${shopId}/`, {
    signal: controllerSignal,
    method: 'GET',
    credentials: 'same-origin',
  });

const createShop = async (shop, setShop, callback) => {
  return await customFetch(`/shops/shop/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    body: JSON.stringify(shop),
  })
    .then((response) => {
      if (!response.response.ok) {
        alert('Shop Creation Failed!');
      }
      return response.data;
    })
    .then((shopData) => {
      setShop(shopData);
      callback('/staff-admin/shops');
    })
    .catch((err) => console.error(err));
};

const updateShop = async (shopId, shop, setShop, callback) => {
  return await customFetch(`/shops/shop/${shopId}/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
    body: JSON.stringify(shop),
  })
    .then((response) => {
      console.log(response);
      if (!response.response.ok) {
        alert('Update Failed');
        callback(`/staff-admin/shops`);
      } else {
        callback(`/staff-admin/shops/`);
      }
      return response.data;
    })
    .then((shopData) => {
      setShop(shopData);
    })
    .catch((err) => console.error(err));
};

const deleteShop = async (shopId) => {
  return await customFetch(`/shops/shop/${shopId}/`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'X-CSRFToken': getCsrfToken(),
    },
  })
    .then((response) => {
      console.log('Delete shop response:', response);
      return response.response.status;
    })
    .catch((err) => console.log('SHOP DELETE ERROR:', err));
};

export {
  fetchAllShops,
  fetchSingleShop,
  updateShop,
  fetchProducts,
  fetchBrands,
  createShop,
  deleteShop,
};
