import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export default function DeleteConfirmation(props) {
  const { children, open, setOpen, onConfirm } = props;

  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-delete">
      <DialogTitle id="confirm-delete">Confirm Delete</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          color="error"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
