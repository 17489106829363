import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';

let originalRequest = async (url, config) => {
  let response = await fetch(url, config);
  let data = {};
  const textData = await response.text();
  try {
    if (response.status !== 204) {
      data = await JSON.parse(textData);
    }
  } catch (err) {
    console.log(err);
    console.log(textData);
  }
  return { response, data };
};

let refreshAccessToken = async (refreshToken) => {
  let response = await fetch('/auth/token/refresh/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refresh: refreshToken }),
  });
  let data = await response.json();
  sessionStorage.setItem('auth_token', data.access);
  return data.access;
};

let customFetch = async (url, config = {}) => {
  let accessToken = sessionStorage.getItem('auth_token');
  let refreshToken = sessionStorage.getItem('auth_refresh_token');
  //   let authTokens = sessionStorage.getItem("authTokens")
  //     ? JSON.parse(sessionStorage.getItem("authTokens"))
  //     : null;
  if (!accessToken) {
    accessToken = await refreshAccessToken(refreshToken);
  }
  const user = jwtDecode(accessToken);
  const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

  if (isExpired) {
    accessToken = await refreshAccessToken(refreshToken);
  }

  //   config["headers"] = {
  //     Authorization: `Bearer ${accessToken}`,
  //   };

  let { response, data } = await originalRequest(url, config);

  if (response.statusText === 'Unauthorized') {
    accessToken = await refreshAccessToken(refreshToken);

    // config["headers"] = {
    //   Authorization: `Bearer ${accessToken}`,
    // };

    let newResponse = await originalRequest(url, config);
    response = newResponse.response;
    data = newResponse.data;
  }

  return { response, data };
};
export default customFetch;
